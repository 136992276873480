<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p class="fs-18 c-9 fw-7">活动信息</p>
      <el-form
        class="mt-24"
        label-width="100px"
        :model="act_form"
        :rules="rules"
        ref="act_form"
      >
        <el-form-item label="活动名称" prop="bargainName">
          <el-input
            class="form-input"
            maxlength="15"
            show-word-limit
            placeholder="请输入活动标题"
            v-model="act_form.bargainName"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="startTime">
          <el-date-picker
            :disabled="isDisabled"
            v-model="act_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            @change="getDateTime"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动规则" prop="activityRule">
          <el-input
            type="textarea"
            class="form-input"
            v-model="act_form.activityRule"
            clearable
            show-word-limit
            :maxlength="500"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="分享图片" prop="sharePicture">
          <UploadPics
            :uploadType="'pics'"
            :imgLimit="1"
            :imgSize="'500*400'"
            :imgsPreview="act_form.sharePicture"
            @getImgData="getImgData"
            exceedInfo="分享图"
          />
        </el-form-item>
        <el-form-item label="分享标题" prop="shareTitle">
          <el-input
            class="form-input"
            v-model="act_form.shareTitle"
          ></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">砍价商品设置</p>
        <el-form-item label="砍价商品" prop="goodsId">
          <div>
            <el-button
              type="primary"
              plain
              @click="selectGoods(selectedGoods)"
              :disabled="isDisabled"
              >{{ act_form.goodsId ? "重新选择" : "添加" }}</el-button
            >
          </div>
          <el-table
            class="mt-24 member-table"
            :data="selectedGoods"
            width="70%"
            v-show="act_form.goodsId"
          >
            <template v-for="item in tableHeader">
              <el-table-column
                :label="item.value"
                :key="item.key"
                v-if="item.key !== 'operations'"
              >
                <template slot-scope="scope">
                  <el-avatar
                    v-if="item.key == 'imgUrl'"
                    :src="picsPath(scope.row.imgUrl)"
                    shape="square"
                    alt="加载失败"
                    :key="scope.row.imgUrl"
                  ></el-avatar>
                  <template v-else>{{ scope.row[item.key] }}</template>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">砍价规则设置</p>
        <el-form-item
          label="最低成交额"
          prop="lowerPrice"
          :rules="[
            {
              validator: (x, y, z) => myValidate(x, y, z, 'price2'),
              required: true,
              trigger: 'change',
            },
          ]"
        >
          <el-input
            class="form-input-200"
            controls-position="right"
            v-model="act_form.lowerPrice"
            :disabled="isDisabled"
          ></el-input>
          <span class="ml-15">元</span>
        </el-form-item>
        <el-form-item
          label="发起限制"
          prop="limit"
          :rules="[
            {
              validator: (x, y, z) => myValidate(x, y, z, 'num'),
              required: true,
              trigger: 'change',
            },
          ]"
        >
          <span class="c-9">每位用户限发起</span>
          <el-input
            class="form-input-200 ml-15"
            v-model="act_form.limit"
            controls-position="right"
            :disabled="isDisabled"
          ></el-input>
          <span class="c-9 ml-15">次砍价活动</span>
          <p class="c-9">注：输入0代表不限制次数</p>
        </el-form-item>
        <!-- <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">砍价金额设置</p> -->
        <el-form-item label="砍价金额" prop="bargainType">
          <el-radio-group
            v-model="act_form.bargainType"
            @change="chooseType"
            :disabled="isDisabled"
          >
            <el-radio :label="1">固定金额</el-radio>
            <el-radio :label="2">随机金额</el-radio>
            <el-radio :label="3">阶梯式砍价</el-radio>
          </el-radio-group>
        </el-form-item>
        <div
          class="bg-f26 ptb-20"
          v-if="typeof act_form.bargainType == 'number'"
        >
          <el-row class="mb-20  ml-20"
            >概率设置
            <span class="c-6 ml-20 fs-12">{{ ripoffWord }}</span></el-row
          >
          <div v-if="act_form.bargainType === 1">
            <el-form-item
              label="固定金额"
              prop="bargainMoney1"
              :rules="[
                {
                  validator: (x, y, z) => myValidate(x, y, z, 'price2'),
                  required: false,
                  trigger: 'change',
                },
              ]"
            >
              <el-input
                :disabled="isDisabled"
                class="form-input"
                v-model="act_form.bargainMoney1"
                controls-position="right"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </div>
          <div v-else-if="act_form.bargainType === 2">
            <el-row>
              <el-col :span="5">
                <el-form-item
                  label="随机金额"
                  prop="bargainMoney1"
                  :rules="[
                    {
                      validator: (x, y, z) => myValidate(x, y, z, 'price2'),
                      required: false,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input
                    :disabled="isDisabled"
                    class="form-input-200"
                    v-model="act_form.bargainMoney1"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1" class="mt-12"> 至 </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item
                  prop="bargainMoney2"
                  label-width="0"
                  :rules="[
                    {
                      validator: (x, y, z) =>
                        myValidate(x, y, z, 'price', act_form.bargainMoney1),
                      required: false,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input
                    :disabled="isDisabled"
                    class="form-input-200"
                    v-model="act_form.bargainMoney2"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!--以下 阶梯式砍价   bargainType==3 -->
          <div v-else>
            <div
              v-for="(item, index) in act_form.stairBargain"
              :key="item.title"
            >
              <p class="fw-7 ml-20 stage-p">{{ item.title }}   <span class="delete-span" @click="deleteStage(index)" v-if="index && index==(act_form.stairBargain.length-1) && !isDisabled">删除</span> </p>
              <el-form-item
                label="砍价进度"
                label-width="150px"
                :prop="'stairBargain[' + index + '].progress'"
                :rules="[
                  {
                    validator: (x, y, z) => myValidate(x, y, z, 'progress'),
                    required: true,
                    trigger: 'change',
                  },
                ]"
              >
                <el-input
                  :disabled="isDisabled"
                  class="form-input"
                  v-model="item.progress"
                  controls-position="right"
                  :min="0"
                >
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
              <el-form-item
                label="砍价刀数"
                label-width="150px"
                :prop="'stairBargain[' + index + '].num'"
                :rules="[
                  {
                    validator: (x, y, z) => myValidate(x, y, z, 'num'),
                    required: true,
                    trigger: 'change',
                  },
                ]"
              >
                <el-input
                  :disabled="isDisabled"
                  class="form-input"
                  v-model="item.num"
                  controls-position="right"
                  :min="0"
                >
                  <template slot="append">次</template>
                </el-input>
              </el-form-item>
              <el-row v-show="item.num">
                <el-col :span="5">
                  <el-form-item
                    label="每刀砍价区间值"
                    label-width="150px"
                    :prop="'stairBargain[' + index + '].startPercent'"
                    :rules="[
                      {
                        validator: (x, y, z) =>
                          myValidate(x, y, z, 'startPercent',item.startPercent, item.num),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input
                      :disabled="isDisabled"
                      v-model="item.startPercent"
                    >
                      <template slot="append">%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :prop="'stairBargain[' + index + '].endPercent'"
                    label="至"
                    label-width="40px"
                    :rules="[
                      {
                        validator: (x, y, z) =>
                          myValidate(
                            x,
                            y,
                            z,
                            'endPercent',
                            act_form.stairBargain[index].startPercent,
                            item.num
                          ),
                        required: true,
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-input :disabled="isDisabled" v-model="item.endPercent">
                      <template slot="append">%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row class='mb-20'><el-col :offset="3" class='c-9'>若不填写区间值，默认1%~99%</el-col></el-row> -->
            </div>
            <el-button
              type="primary"
              class="form-button ml-20"
              @click="addStage"
              >添加</el-button
            >
          </div>
        </div>
        <p class="c-ff">* 活动进行中，砍价的规则不允许修改</p>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button plain class="form-button" @click="cancel">取消</el-button>
      <el-button
        class="form-button"
        type="primary"
        @click="submit"
        >确定</el-button
      >
    </div>

    <!-- 商品选择弹窗 -->
    <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      @getSelectedList="getSelectedList"
      @getTableList="getTableList"
      :selected="false"
    ></DepartmentGoodsDialog>
  </div>
</template>
<script>
import request from "@/utils/request";
import Pagination from "@/components/Pagination/index";
import UploadPics from "@/components/UploadPics";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import {
  subNewRipoff,
  subEditRipoff,
  getRipoffDetail,
} from "@/api/maketing/ripoff";
import {
  getProcudtList,
  getStoreList,
  getProductDetail,
} from "@/api/online_department_store/commodity_manage";
export default {
  components: {
    Pagination,
    UploadPics,
    DepartmentGoodsDialog,
  },
  data() {
    let myValidate = (rule, value, callback, str, num, num1) => {
      // console.log(rule);
      // console.log(value);
      // console.log(callback);
      // console.log(str);
      // console.log(num); //开始值
      // console.log(num1);  //砍价刀数
      switch (str) {
        case "progress":
          let pre = 0;
          this.act_form.stairBargain.forEach((ele) => {
            pre += Number(ele.progress);
          });
          if (value === "" || value === undefined || value == null) {
            callback(new Error("不能为空"));
          } else if (!/^\d{1,2}|100$/.test(value)) {
            callback(new Error("不超过两位小数的数字"));
          } else if (pre != 100) {
            callback(new Error("砍价百分比相加不为100"));
          } else {
            callback();
          }
          break;
        case "num":
          if (!/^\d+$/.test(value)) {
            callback(new Error("是数字"));
          } else if (value === undefined || value === null || value === "") {
            callback(new Error("不能为空"));
          } else {
            callback();
          }
          break;
        case "startPercent":
          if (value === "" || value === undefined || value == null) {
            // value = 1
            callback(new Error("不能为空"));
            callback();
          } 
          // else if (!/^([1-9]\d?)$/.test(value)) {
          //   callback(new Error("1-99的数字"));
          // }
            else if (Number(value)<0.01) {
            callback(new Error("必须大于等于0.01"));
          }
           else if((100/Number(num1)<Number(value))){
             callback(new Error("区间值为0.01%~"+100/Number(num1)+"%之间"));
          }
          else {
            callback();
          }
          break;
        case "endPercent":
          if (!value) {
            // value = 99
            callback(new Error("不能为空"));
            callback();
          } 
          // else if (!/^([1-9]\d?)$/.test(value)) {
          //   callback(new Error("1-99的数字"));
          // } 
          else if (Number(value) <= Number(num)) {
            callback(new Error("后者须大于前者"));
          }else if((100/Number(num1)<Number(value))){
             callback(new Error("区间值为0.01%~"+100/Number(num1)+"%之间"));
          }
           else {
            callback();
          }
          break;
        case "price":
          if (value === "" || value === undefined || value == null) {
            callback(new Error("不能为空"));
          } else if (!/^\d{1,}(\.{0}|\.{1}\d{0,2})?$/.test(value)) {
            callback(new Error("两位小数"));
          } else if (Number(value) <= Number(num)) {
            callback(new Error("后者须大于前者"));
          } else {
            callback();
          }
          break;
        case "price2":
          if (value === "" || value === undefined || value == null) {
            callback(new Error("不能为空"));
          } else if (!/^\d{1,}(\.{0}|\.{1}\d{0,2})?$/.test(value)) {
            callback(new Error("两位小数"));
          } else {
            callback();
          }
          break;
        default:
      }
    };
    return {
      bargainType: 0,
      act_form: {
        bargainName: "",
        goodsId: "",
        startTime: "",
        endTime: "",
        bargainType: null,
        bargainMoney1: "",
        bargainMoney2: "",
        lowerPrice: "",
        limit: "",
        status: 1,
        goodsType: "",
        activityRule: "",
        sharePicture: "",
        shareTitle: "",
        stairBargain: [],
      },
      ripoffWord: "",
      act_date: [],
      picPreview: [],
      storeList: [],
      rules: {
        bargainName: [
          { required: true, message: "活动名称不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "活动时间不能为空", trigger: "blur" },
        ],
        goodsId: [
          { required: true, message: "砍价商品不能为空", trigger: "blur" },
        ],
        limit: [
          { required: true, message: "发起限制不能为空", trigger: "blur" },
        ],
        bargainType: [{ required: true, message: "砍价金额不能为空" }],
        activityRule: [
          { required: true, message: "活动规则不能为空", trigger: "blur" },
        ],
        sharePicture: [{ required: true, message: "分享图片不能为空" }],
        shareTitle: [{ required: true, message: "分享标题不能为空" }],
      },
      myValidate: myValidate,
      showGoods: false,
      tableHeader: [
        // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode",
        },
        {
          value: "商品图片",
          key: "imgUrl",
        },
        {
          value: "商品名称",
          key: "goodsName",
        },
         {
          value: "所属门店",
          key: "storeName",
        },
        {
          value: "一级分类",
          key: "firstClassifyName",
        },
        {
          value: "二级分类",
          key: "secondClassifyName",
        },
        {
          value: "原价",
          key: "markPrice",
        },
        {
          value: "库存",
          key: "stock",
        },
        {
          value: "操作",
          key: "operations",
        },
      ],
      search_gift: "",
      hasSelected: false,
      selectedGoods: [],
      addFlag: true,
      actId: "",
      total: 0,
      pageNum: 1,
      pageSize: 5,
      isDisabled: false,
    };
  },
  mounted() {
    this.handleOpeType();
  },
  methods: {
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.storeList = data.body.list;
      });
    },
    //活动编辑态处理
    handleOpeType() {
      let params = this.$route.params;
      this.addFlag =
        params.addFlag != undefined
          ? params.addFlag
          : sessionStorage.getItem("ADD_FLAG");
      if (params.addFlag != undefined)
        sessionStorage.setItem("ADD_FLAG", params.addFlag);
      if (params.id) sessionStorage.setItem("ACT_ID", params.id);
      if (String(this.addFlag) == "false") {
        this.actId = params.id || sessionStorage.getItem("ACT_ID");
        this.getActData(this.actId);
      }
    },
    //获取活动详情
    getActData(id) {
      getRipoffDetail(id).then((res) => {
        let data = res.data.body;
        data.stairBargain = JSON.parse(data.stairBargain);
        this.isDisabled = data.bargainStatus == "未开始" ? false : true;
        this.getGiftData(data.goodsId);
        this.act_form = data;
        this.act_date = [data.startTime, data.endTime];
      });
    },
    getGiftData(id) {
      if (!id) return;
      getProductDetail(id).then((res) => {
        this.selectedGoods = [res.data.body];
      });
    },
    getImgData(imgData) {
      //图片回显
      // console.log(imgData);
      this.act_form.sharePicture = imgData.subImgs;
    },
    // 获取已勾选商品
    getSelectedList(data, index) {
      // console.log(data);
      // console.log(index);
      this.act_form.goodsId = data.goodsId;
      this.selectedGoods = [{ ...data }];
    },
    getTableList(data) {
      //根据搜索条件改变列表
      getProcudtList({ goodsStatus: 1, ...data }).then((res) => {
        let data = this.$refs.GoodsDialog;
        data.total = res.data.body.total;
        data.tableData = res.data.body.list;
      });
    },
    // 打开商品列表弹窗
    selectGoods() {
      // console.log("list");
      let data = this.$refs.GoodsDialog;
      data.act_form.list = [];
      data.selected_temp = [];
      data.showGoods = true;
      data.getGoods(false);
    },
    //获取日期时间
    getDateTime() {
      this.act_form.startTime = this.act_date[0];
      this.act_form.endTime = this.act_date[1];
    },
    chooseType(e) {
      //砍价金额改变
      // console.log(typeof e);
      if (this.act_form.bargainType === 1) {
        // console.log(0);
        this.ripoffWord = "最多支持两位小数，砍价金额需小于商品价格";
      } else if (this.act_form.bargainType === 2) {
        this.act_form.bargainMoney2 = "";
        this.ripoffWord = "最多支持两位小数，砍价金额需小于商品价格";
      } else {
        this.ripoffWord = "砍价阶段设置最多3级，砍价所有进度加起来必须100%";
        this.act_form.stairBargain = [
          {
            title: "阶段一",
            progress: "",
            num: "",
            startPercent: "",
            endPercent: ""
          },
        ];
      }
    },
    //点击添加阶段
    addStage() {
      //添加阶梯式砍价规则
      let length = this.act_form.stairBargain.length;
      if (length == 3) {
        this.$message.error("超过最大限制");
        return;
      } else {
        this.$set(this.act_form.stairBargain, length, {
          title: length == 1 ? "阶段二" : "阶段三",
          progress: "",
          num: "",
          startPercent: "",
          endPercent: ""
        });
      }
    },
    //点击删除阶段
    deleteStage(index){
    this.act_form.stairBargain.splice(index,1);
    },
    selectStore(e) {
      // console.log(e);
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    cancel() {
      this.$router.go(-1);
    },
    submit() {
      this.$refs["act_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    async submitForm() {
      let time_data = {
        startTime: this.act_date[0],
        endTime: this.act_date[1],
      };
      let form = JSON.parse(JSON.stringify(this.act_form));
      form.stairBargain.map((ele) => {
        if (
          ele.startPercent == "" ||
          ele.startPercent == undefined ||
          ele.startPercent == null
        ) {
          ele.startPercent = "1";
        }
        if (
          ele.endPercent == "" ||
          ele.endPercent == undefined ||
          ele.endPercent == null
        ) {
          ele.endPercent = "99";
        }
      });
      form.stairBargain = JSON.stringify(form.stairBargain);
      let data = Object.assign(form, time_data);
      let method = "post";
      if (String(this.addFlag) == "false") {
        method = "put";
        data.id = this.actId;
      }
      // console.log(data);
      let res;
      if (String(this.addFlag) == "false") {
        res = await subEditRipoff(data);
      } else {
        res = await subNewRipoff(data);
      }

      if (res && res.data.code == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
.b-f2 {
  background: #f2f2f6;
}
.stage-p{
  display: flex;
  justify-content: flex-start;
}
.delete-span{
  margin-left: 650px;
  color: red;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
</style>
